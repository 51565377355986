import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, InputNumber, Popconfirm, notification } from 'antd'
import { useGetUserDetailsQuery, useUpdateUserDetailsMutation, useGetUserNeighboursQuery } from '../../services/auth/authService'
const { Option } = Select;

const EditSubComponent = () => {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [selectedAddress, setSelectedAddress] = useState('');
    const { data: userInfo = {}, isFetching: isUserInfoFetching } = useGetUserDetailsQuery()
    const [updateInfo, { isLoading: isUpdateInfoLoading }] = useUpdateUserDetailsMutation()
    const { refetch } = useGetUserNeighboursQuery()
    useEffect(() => {
        form.resetFields()
        setSelectedAddress(userInfo.address)
    }, [userInfo, form])
    const openSuccessNotification = (mes) => {
        api.success({
            message: 'Повідомлення',
            description: mes ?? 'Ваші зміни успішно збережено'
        });
    };
    const openErrorNotification = (mes) => {
        api.error({
            message: 'Повідомлення',
            description: mes
        });
    };
    const showPopconfirm = () => {
        if (userInfo.bills?.some(i => !i.paid)) {
            openErrorNotification('Спочатку оплатіть неоплачені рахунки')
            return
        }
        if (userInfo.blocked) {
            updateInfo({
                phone: userInfo.phone,
                address: userInfo.address,
                flat: userInfo.flat,
                keys_count: userInfo.keys_count,
                blocked: !userInfo.blocked
            }).unwrap().then(() => {
                openSuccessNotification('Успішно розблоковано')
                refetch()
            })
        }
    };
    const handleOkOfConfirmation = () => {
        updateInfo({
            phone: userInfo.phone,
            address: userInfo.address,
            flat: userInfo.flat,
            keys_count: userInfo.keys_count,
            blocked: !userInfo.blocked,
        }).unwrap().then(() => {
            openSuccessNotification('Успішно заблоковано')
            refetch()
        })
    };
    const onFormSave = (values) => {
        Object.keys(values).forEach(key => values[key] === undefined && delete values[key]) // remove undefined
        updateInfo({
            phone: userInfo.phone,
            address: userInfo.address,
            flat: userInfo.flat,
            keys_count: userInfo.keys_count,
            ...values
        }).unwrap().then(() => {
            openSuccessNotification('Дані успішно збережені')
            refetch()
        }).catch(rejected => {
            openErrorNotification(Object.values(rejected?.data).join(''))
        })
    }
    const flatNumbers = () => {
        const flats = [...Array(170).keys()].map((i) => i + 1);
        flats.splice(162, 0, "162-a")
        flats.splice(167 + 1, 0, "167-1", "167-2", "167-3", "167-4")
        flats.splice(170 + 5, 0, "170-1", "170-2", "170-3", "170-4")
        return flats.map((i) => <Option key={i} value={i}>{i}</Option>)
    }
    return (
        <div>
            {contextHolder}
            <Form
                form={form}
                name="edit"
                initialValues={{
                    first_name: userInfo.first_name,
                    last_name: userInfo.last_name,
                    phone: userInfo.phone,
                    address: userInfo.address,
                    flat: userInfo.flat,
                    keys_count: userInfo.keys_count,
                    firm_name: userInfo.firm_name
                }}
                onFinish={onFormSave}
                layout="vertical"
                size="middle"
                className='edit-form'
            >
                <Form.Item
                    label="Ім'я"
                    name="first_name"
                    rules={[
                        {
                            required: true,
                            message: 'Будь ласка вкажіть ваше ім\'я',
                        },
                        {
                            min: 3,
                            message: 'Мінімальна довжина має бути 3 символи',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Прізвище"
                    name="last_name"
                    rules={[
                        {
                            required: true,
                            message: 'Будь ласка вкажіть ваше прізвище',
                        },
                        {
                            min: 3,
                            message: 'Мінімальна довжина має бути 3 символи',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Номер телефону"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Будь ласка вкажіть номер телефону',
                        },
                        {
                            pattern: '^\\+380[0-9]{9}$',
                            message: 'Вкажіть правильний номер телефону'
                        }
                    ]}
                >
                    <Input disabled type="tel" />
                </Form.Item>
                <Form.Item
                    label="Адреса"
                    name="address"
                    rules={[
                        {
                            required: true,
                            message: 'Будь ласка вкажіть адресу',
                        },
                    ]}
                >
                    <Select onChange={(val) => setSelectedAddress(val)}>
                        <Option key={1} value="Вадима Гетьмана 1А">
                            Вадима Гетьмана 1А
                        </Option>
                        <Option key={2} value="Вадима Гетьмана 1Б">Вадима Гетьмана 1Б</Option>
                        <Option key={3} value="Вадима Гетьмана 1В">Вадима Гетьмана 1В</Option>
                        <Option key={4} value="Вадима Гетьмана 1">Вадима Гетьмана 1</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Квартира"
                    name="flat"
                    rules={[
                        {
                            required: true,
                            message: 'Будь ласка вкажіть квартиру',
                        },
                    ]}
                >
                    <Select showSearch>
                        {flatNumbers()}
                    </Select>
                </Form.Item>
                {selectedAddress === "Вадима Гетьмана 1" &&
                    <div>
                        <Form.Item
                            label="Кількість ключів"
                            name="keys_count"
                            rules={[
                                {
                                    required: true,
                                    message: 'Будь ласка вкажіть кількість ключів',
                                },
                            ]}
                        >
                            <InputNumber min={1} controls={false} />
                        </Form.Item>
                        <Form.Item
                            label="Назва фірми"
                            name="firm_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Будь ласка вкажіть назву фірми',
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                }
                <Form.Item
                    label="Пароль"
                    name="password"
                    tooltip='Якщо залишити пустим пароль не зміниться'
                    rules={[
                        {
                            min: 6,
                            message: 'Мінімальна довжина паролю має бути 6 символів',
                        },
                    ]}
                >
                    <Input.Password autoComplete="new-password" />
                </Form.Item>

                <Form.Item>
                    <Button loading={isUpdateInfoLoading} block type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Popconfirm
                        title="Підтвердження"
                        description="Ви впевнені, що хочете заблокувати акаунт?"
                        okText="Так"
                        cancelText="Ні"
                        onConfirm={handleOkOfConfirmation}
                        okButtonProps={{
                            loading: isUpdateInfoLoading,
                        }}
                        disabled={userInfo.blocked}
                    >
                        <Button loading={isUpdateInfoLoading || isUserInfoFetching} onClick={showPopconfirm} block danger={!userInfo.blocked} type="primary">
                            {userInfo.blocked ? 'Розблокувати' : 'Заблокувати'} акаунт
                        </Button>
                    </Popconfirm>
                </Form.Item>
            </Form>
        </div>
    )
}

export default EditSubComponent