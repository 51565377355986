import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PhoneOutlined } from '@ant-design/icons';
import backgroundImage from '../img/bg_clear.jpg';
import { Button, Flex, Form, Input, notification, Typography, theme } from 'antd';
import { motion } from "framer-motion";
import { useVerifyUserCodeMutation } from '../services/auth/authService';
const { Title } = Typography;
const { useToken } = theme;

const Verify = () => {
  const [form] = Form.useForm();
  const { token } = useToken();
  const [verifyUserCode, { isFetching }] = useVerifyUserCodeMutation()
  const navigate = useNavigate();
  const openSuccessNotification = () => {
    notification.success({
      message: 'Повідомлення',
      description: 'Номер телефону успішно підтверджено',
      duration: 0
    });
  };
  const openErrorNotification = () => {
    notification.warning({
      message: 'Повідомлення',
      description: 'Неправильний код, спробуйте будь ласка ще раз'
    });
  };

  const onFormSave = (values) => {
    verifyUserCode(values).unwrap().then(() => {
      openSuccessNotification()
      navigate('/profile')
    }).catch(() => openErrorNotification())
  }
  return (
    <Flex justify="center" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }} align="center">
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.3 }}
      >
        <Form
          style={{ backgroundColor: token.colorBgBase }}
          form={form}
          name="register"
          initialValues={{
          }}
          onFinish={onFormSave}
          layout="vertical"
          className='register-form'
          size='default'
        >
          <Title level={2} style={{ textAlign: 'center' }}><PhoneOutlined />Підтвердження номеру</Title>
          <Form.Item
            label="Код з смс"
            name="code"
            tooltip="Якщо не підтвердити номер телефону, то акаунт буде видалено протягом 5 хв"
            rules={[
              {
                required: true,
                message: 'Будь ласка вкажіть код',
              },
              {
                len: 6,
                message: 'Код має містити 6 символів'
              }
            ]}
          >
            <Input type='number' showCount />
          </Form.Item>

          <Form.Item>
            <Button loading={isFetching} block type="primary" htmlType="submit">
              ПІДТВЕРДИТИ
            </Button>
          </Form.Item>
        </Form>
      </motion.div>
    </Flex>
  );
};
export default Verify;