import React from 'react';
import {Navigate} from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { userLogout } from '../features/auth/authActions';
import { util } from '../services/auth/authService';

const Logout = () => {
  const dispatch = useDispatch()
  dispatch(util.resetApiState())
  dispatch(userLogout())
  return <Navigate to="/" replace />;
};
export default Logout;