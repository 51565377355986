import { createSlice } from '@reduxjs/toolkit'
import { userLogin, userLogout, userRegistering, resetStore } from './authActions'

const userToken = localStorage.getItem('token')
  ? localStorage.getItem('token')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userToken = payload.token
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    // registering user
    [userRegistering.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userRegistering.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userToken = payload.token
      state.success = true
    },
    [userRegistering.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    //logout user
    [userLogout.fulfilled]: (state) => {
      state.loading = false
      state.userToken = null
    },
    // reset store
    [resetStore.fulfilled]: (state) => {
      state.loading = false
      state.error = null
      state.success = false
    }
  },
})

export default authSlice.reducer