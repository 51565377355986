import { Provider } from 'react-redux'
import LayoutComponent from './Components/Layout/LayoutComponent.js';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { ConfigProvider, theme } from 'antd';
import ua from 'antd/locale/uk_UA.js';
import store from './store'
import './App.css';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ConfigProvider
        componentSize={'large'}
        locale={ua}
          theme={{
            algorithm: theme.defaultAlgorithm,
            token: {
              borderRadius: 2,
            },
          }}>
          <LayoutComponent />
        </ConfigProvider>
      </Router>
    </Provider>
  );
}

export default App;
