import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../img/bg_clear.jpg';
import { theme, Flex, Button, Typography, Avatar, Row, Col } from 'antd';
import { LockTwoTone, DollarTwoTone, LaptopOutlined } from '@ant-design/icons';
import { motion } from "framer-motion";
const { Title, Text } = Typography;

const Home = () => {

  const {
    token: { colorWhite, colorPrimary },
  } = theme.useToken();
  const boxStyle = {
    width: '100%',
    height: 520,
    borderRadius: 6,
    background: `url(${backgroundImage}) fixed no-repeat`,
    backgroundSize: 'cover',
    color: colorWhite
  };
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.auth)
  return (
    <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.3 }}
      >
      <Flex vertical={true} style={boxStyle} justify='center' align='center'>
        <Title level={2} style={{ color: 'white', margin: 0 }}>Гетьмана 1</Title>
        <Title level={4} style={{ color: 'white', margin: 15 }}>територія нашого комплексу</Title>
        <Button onClick={() => userToken === null ? navigate('/sign-in') : navigate('/profile')} style={{ width: 350, height: 50 }} shape='round' size='large' type="primary">РОЗПОЧАТИ</Button>
        <Title level={5} style={{ color: 'white', margin: 15, marginBottom: 0}}>Ми надаємо послугу щодо утримання території комплексу у належному стані.</Title>
        <Title level={5} style={{ color: 'white', margin: 15, marginTop: 0}}>Оплата за послугу здійснюється щомісяця в розмірі 50 (п'ятдесят) гривень за 1 акаунт</Title>
      </Flex>
      <Flex style={{ marginTop: 15 }} vertical justify='center' align='center'>
        <Title level={2} style={{ marginTop: 40 }}>ПЕРЕВАГИ</Title>
      </Flex>
      <Row style={{ marginTop: 15, marginBottom: 40 }} justify='center' align='center'>
        <Col sm={8} md={8} lg={8} xl={8} xxl={8}>
          <Flex flex={1} gap='large' style={{ marginTop: 15 }} vertical justify='center' align='center'>
            <Avatar style={{ backgroundColor: 'white' }} shape="circle" size={64} icon={<LockTwoTone twoToneColor={colorPrimary} />} />
            <Title level={4} style={{ marginTop: 10, marginBottom: 0 }}>Безпека</Title>
            <Text style={{ marginTop: 0, fontSize: 20 }}>Ваші дані надійно захищені</Text>
          </Flex>
        </Col>
        <Col sm={8} md={8} lg={8} xl={8} xxl={8}>
          <Flex flex={1} gap='large' style={{ marginTop: 15 }} vertical justify='center' align='center'>
            <Avatar style={{ backgroundColor: 'white' }} shape="circle" size={64} icon={<DollarTwoTone twoToneColor={colorPrimary} />} />
            <Title level={4} style={{ marginTop: 10, marginBottom: 0 }}>Стан балансу</Title>
            <Text style={{ marginTop: 0, fontSize: 20 }}>Контролюйте баланс вашого рахунку прямо з браузера</Text>
          </Flex>
        </Col>
        <Col sm={8} md={8} lg={8} xl={8} xxl={8}>
          <Flex flex={1} gap='large' style={{ marginTop: 15 }} vertical justify='center' align='center'>
            <Avatar style={{ backgroundColor: 'white' }} shape="circle" size={64} icon={<LaptopOutlined style={{ color: colorPrimary }} />} />
            <Title level={4} style={{ marginTop: 10, marginBottom: 0 }}>На всіх пристоях</Title>
            <Text style={{ marginTop: 0, fontSize: 20 }}>Наша программа працює і на ПК і на смартфонах</Text>
          </Flex>
        </Col>
      </Row>
    </motion.div>
  );
};
export default Home;