import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Descriptions, Tag, Table, InputNumber, Flex, Form, Spin, Button, Card, notification, Alert, Modal } from 'antd';
import { useGetUserDetailsQuery, useGetUserNeighboursQuery, useUpdateUserDetailsMutation, useGetLiqpayFormMutation, useBuyKeychainMutation } from '../../services/auth/authService'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../features/auth/authActions';
import 'dayjs/locale/uk';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

const { Meta } = Card;

const ProfileSubComponent = () => {
    const [modal, modalContext] = Modal.useModal();
    const dispatch = useDispatch()
    const [api, contextHolder] = notification.useNotification();
    dayjs.extend(LocalizedFormat);
    const navigate = useNavigate();
    const { data: userInfo = {}, isFetching: isUserInfoFetching, error } = useGetUserDetailsQuery()
    const { data: neighbours = [], isFetching: isNeghboursFetching } = useGetUserNeighboursQuery()
    const [getLiqPayForm, { isFetching: isLiqpayLoading, data: liqpayForm }] = useGetLiqpayFormMutation()
    const [updateInfo, { isLoading: isUpdateInfoLoading }] = useUpdateUserDetailsMutation()
    const [buyKeychain, { isLoading: isBuyKeychainLoading}] = useBuyKeychainMutation()
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showSaveBtn, setShowSaveBtn] = useState(false);
    const [sum, setSum] = useState(50);
    const openNotification = (text) => {
        api.success({
            message: 'Повідомлення',
            description: text ?? 'Ваші зміни успішно збережено'
        });
    };
    const openErrorNotification = (text) => {
        api.error({
            message: 'Повідомлення',
            description: text ?? 'Ваші зміни успішно збережено'
        });
    };
    const profileItems = [
        {
            key: '1',
            label: 'Баланс',
            children: userInfo.balance,
        },
        {
            key: '2',
            label: 'Стан акаунту',
            children: userInfo.blocked ? <Tag color="error">заблоковано</Tag> : <Tag color="success">активний</Tag>,
        },
    ];
    const profileItemsLoading = [
        {
            key: '1',
            label: 'Баланс',
            children: <Spin />,
        },
        {
            key: '2',
            label: 'Стан акаунту',
            children: <Spin />,
        },
    ];
    const columns = [
        {
            title: 'Ім\'я',
            dataIndex: 'first_name',
            key: 'first_name',
            width: 10
        },
        {
            title: 'Моб. Телефон',
            dataIndex: 'phone',
            key: 'phone',
            width: 10
        },
        {
            title: 'Баланс',
            dataIndex: 'balance',
            key: 'balance',
            width: 10
        },
        {
            title: 'Стан акаунту',
            key: 'status',
            dataIndex: 'status',
            width: 1,
            render: (_, record) => record.blocked ? <Tag color="error">неактивн</Tag> : <Tag color="success">активний</Tag>
        },
    ];
    const rowSelection = {
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
            if (userInfo.paying_for !== null) {
                let diff = selectedRowKeys.filter(x => !userInfo.paying_for.includes(x));
                let diffR = userInfo.paying_for.filter(x => !selectedRowKeys.includes(x));
                setShowSaveBtn(diff.length > 0 || diffR.length > 0);
            } else {
                setShowSaveBtn(true);
            }
        },
    };
    const onSumChange = (value) => {
        setSum(value);
    }
    useEffect(() => {
        if(error?.status === 401) {
            dispatch(userLogout()).then(() => navigate('/sign-in'))
        }
    }, [dispatch, navigate, error]);
    useEffect(() => {
        if (sum > 0) {
            getLiqPayForm(sum);
        }
    }, [sum, getLiqPayForm]);
    useEffect(() => {
        setSelectedRowKeys(userInfo.paying_for ? userInfo.paying_for : []);
        setShowSaveBtn(false);
    }, [userInfo]);

    return (
        <div>
            {contextHolder}
            {userInfo.verified === false && 
            <Alert
            message="Зверніть увагу"
            description={<div>Ваш номер телефону не підтверджено <Button type="link" onClick={() => navigate('/verify')}>Перейти на сторінку підтвердження</Button></div>}
            type="info"
          />}
            <Descriptions items={isUserInfoFetching ? profileItemsLoading : profileItems} />
            <Table
                title={() => selectedRowKeys.length > 0 ? `Ви платите за ${selectedRowKeys.length} акаунти ${selectedRowKeys.length * 50} грн/місяць` : 'Виберіть акаунти, які бажаєте оплатити'}
                columns={columns}
                pagination={false}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRowKeys,
                    ...rowSelection,
                }}
                rowKey={(record) => record.phone}
                dataSource={neighbours}
                size="small"
                loading={isNeghboursFetching}
            />
            <div>
                <p />
                <Button block disabled={!showSaveBtn} loading={isUpdateInfoLoading} onClick={() => updateInfo({
                    paying_for: selectedRowKeys,
                    phone: userInfo.phone,
                    address: userInfo.address,
                    flat: userInfo.flat,
                    keys_count: userInfo.keys_count,
                }).unwrap().then(() => openNotification())} type="primary">Зберегти</Button>
            </div>
            <p />
            {userInfo.paying_for?.length > 0 &&
                <Card bordered title={<div style={{textAlign:'center'}}>Поповнення рахунку</div>}
                >
                    <Flex align="center" style={{ flexDirection: 'column' }}>
                        <Form.Item label='Сума'><InputNumber type="number" onChange={onSumChange} addonAfter="₴" defaultValue={sum} controls={false} /></Form.Item>
                        {isLiqpayLoading ? <Spin /> : <div dangerouslySetInnerHTML={{ __html: liqpayForm }}></div>}
                    </Flex>
                </Card>
            }
            <Card bordered
            cover={
                <img alt="brelok-logo" id="brelok-logo" src="/img/brelok.png" />
            }
            >
                <Flex align="center" style={{ flexDirection: 'column' }}>
                    <Meta title="Вартість одного брелка 200 грн"/>
                    <p/>
                    <Button shape="round" style={{backgroundColor: '#6CA91C', color: "white", width: '70%', fontSize: 17, fontWeight: '500'}} size="large" loading={isBuyKeychainLoading} onClick={async () => {
                        const result = await modal.confirm({
                            title:'Ви дійсно бажаєте замовити брелок?',
                            content:'При підтвердженні з вашого балансу буде списано 200 грн. Згодом брелок можна буде забрати у консьєржа.'
                        })
                        if (result) {
                            buyKeychain().unwrap().then(() => {
                                openNotification('Ви успішно замовили брелок')
                            }).catch(rejected => {
                                openErrorNotification(rejected?.data === 'Insufficient funds' ? 'Недостатньо коштів' : rejected?.data)
                            })
                        }
                    }}>ЗАМОВИТИ БРЕЛОК</Button>
                </Flex>
            </Card>

        {modalContext}
        </div>
    )
}

export default ProfileSubComponent