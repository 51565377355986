import React, { useState } from 'react';
import { Card, Row, Col, Badge } from 'antd';
import backgroundImage from '../img/bg_clear.jpg';
import ProfileSubComponent from '../Components/Profile/ProfileSubComponent';
import HistorySubComponent from '../Components/Profile/HistorySubComponent';
import EditSubComponent from '../Components/Profile/EditSubComponent'
import ReplenishmentSubComponent from '../Components/Profile/ReplenishmentSubComponent'
import BillSubComponent from '../Components/Profile/BillSubComponent'
import AboutSubComponent from '../Components/Profile/AboutSubComponent';
import { useGetUserDetailsQuery, useGetUserNeighboursQuery } from '../services/auth/authService'
import { AnimatePresence, motion } from "framer-motion";

const Cabinet = () => {
  const [activeTabKey, setActiveTabKey] = useState(localStorage.getItem('tab') ? localStorage.getItem('tab') : 'profile');
  const { data: userInfo = {} } = useGetUserDetailsQuery()
  const tabListNoTitle = [
    {
      key: 'profile',
      label: 'Профіль',
    },
    {
      key: 'replenishment',
      label: 'Поповнення',
    },
    {
      key: 'bill',
      label: (<Badge count={userInfo.bills?.filter(i => !i.paid).length} color="blue" title="К-сть несплачених рахунків" size='small' offset={[10, 0]}>Рахунки</Badge>),
    },
    {
      key: 'transactions',
      label: 'Транзакції',
    },
    {
      key: 'edit',
      label: 'Редагувати',
    },
    {
      key: 'about',
      label: 'Про послуги',
    },
  ];
  useGetUserNeighboursQuery()
  const contentListNoTitle = {
    profile: <ProfileSubComponent />,
    replenishment: <ReplenishmentSubComponent />,
    bill: <BillSubComponent />,
    transactions: <HistorySubComponent />,
    edit: <EditSubComponent />,
    about: <AboutSubComponent />,
  };
  const onTabChange = (key) => {
    setActiveTabKey(key);
    localStorage.setItem('tab', key);
  };
  return (<motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -20 }}
    transition={{ duration: 0.3 }}
  >
    <Row className='layout' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }} justify='center' align='center'>
      <Col>
        <AnimatePresence mode='wait' initial={false}>
          <Card
            className='profile-card'
            bordered={false}
            tabList={tabListNoTitle}
            activeTabKey={activeTabKey}
            onTabChange={onTabChange}
            tabProps={{
              size: 'small',
            }}
          >
            <motion.div
              key={activeTabKey}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {contentListNoTitle[activeTabKey]}
            </motion.div>
          </Card>
        </AnimatePresence>
      </Col>
    </Row>
  </motion.div>);
};
export default Cabinet;