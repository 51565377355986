import { useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'
import {Button, Result} from 'antd'

const ProtectedRoute = () => {
    const { userToken } = useSelector((state) => state.auth)
    if (!userToken) {
        return <Result
            status="403"
            title="403"
            subTitle="Вибачте, у вас не має доступу до цієї сторінки, спочатку увійдіть в свій акаунт."
            extra={<NavLink to='/sign-in'><Button type="primary">Перейти на сторінку входу</Button></NavLink>}
        />
    }

    // returns child route elements
    return <Outlet />
}
export default ProtectedRoute