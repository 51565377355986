import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userToken
            if (token) {
                // include token in req header
                headers.set('Authorization', `Token ${token}`);
                headers.set('X-Csrftoken', document.cookie.csrftoken);
                return headers
            }
        },
    }),
    tagTypes: ['info', 'neighbours'],
    endpoints: (builder) => ({
        getUserDetails: builder.query({
            query: () => ({
                url: '/getInfo/',
                method: 'GET',
            }),
            providesTags: () => ['info']
        }),
        getUserNeighbours: builder.query({
            query: () => ({
                url: '/getNeighbours/',
                method: 'GET',
            }),
            providesTags: () => ['neighbours']
        }),
        getLiqpayForm: builder.mutation({
            query: (amount) => ({
                url: '/liqpay/',
                method: 'POST',
                body: {amount},
                responseHandler: (response) => response.text()
            }),
        }),
        updateUserDetails: builder.mutation({
            query: (body) => ({
                url: '/update/',
                method: 'PUT',
                body
            }),
            invalidatesTags: () => ['info']
        }),
        verifyUserCode: builder.mutation({
            query: (values) => ({
                url: '/verify/',
                method: 'POST',
                body: values,
            }),
            invalidatesTags: () => ['info', 'neighbours']
        }),
        buyKeychain: builder.mutation({
            query: () => ({
                url: '/keychain/',
                method: 'POST',
                body: {},
            }),
            invalidatesTags: () => ['info']
        }),
    }),
});

export const { useGetUserDetailsQuery, useGetUserNeighboursQuery, useUpdateUserDetailsMutation, useGetLiqpayFormMutation, useVerifyUserCodeMutation, useBuyKeychainMutation, util } = authApi