import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import backgroundImage from '../img/bg_clear.jpg';
import { Button, Flex, Form, Input, Select, message, Typography, Divider, theme } from 'antd';
import { userLogin, resetStore } from '../features/auth/authActions';
import { motion } from "framer-motion";
const { Title } = Typography;
const { useToken } = theme;

const SignIn = () => {
  const { Option } = Select;
  const { loading, error, userToken } = useSelector((state) => state.auth)
  const { token } = useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  
  useEffect(() => {
    const errorShow = (text) => {
      messageApi.open({
        type: 'error',
        content: text,
      });
    };
    // redirect authenticated user to profile screen
    if (userToken) navigate('/profile')

    if (error) {
      errorShow(error)
    }
  }, [navigate, error, userToken, messageApi]);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    values.phone = '+380' + values.phone;
    dispatch(userLogin(values));
  };
  const prefixSelector = (
    <Form.Item initialValue={'+380'} name="prefix" noStyle>
      <Select
        style={{
          width: 120,
        }}
      >
        <Option value="+380">+380</Option>
      </Select>
    </Form.Item>
  );
  return (
    <Flex justify="center" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }} align="center">
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.3 }}
      >
        <Form
          style={{ backgroundColor: token.colorBgBase }}
          name="normal_login"
          layout="vertical"
          className="login-form"
          onFinish={onFinish}
        >
          <Title level={2} style={{ textAlign: 'center' }}><UserOutlined />Вхід</Title>
          {contextHolder}
          <Form.Item
            name="phone"
            label="Мобільний номер"
            help={"Номер має мати вигляд: +380123456789"}
            rules={[
              {
                required: true,
                len: 9
              },
            ]}
          >
            <Input tabIndex={1} name='username' allowClear type='number' addonBefore={prefixSelector} />
          </Form.Item>
          <Form.Item
            name="password"
            label="Пароль"
            rules={[
              {
                required: true,
                message: 'Будь ласка, вкажіть пароль',
              },
            ]}
          >
            <Input
              tabIndex={2}
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Пароль"
            />
          </Form.Item>

          <Form.Item>
            <Button tabIndex={3} loading={loading} type="primary" htmlType="submit" className="login-form-button">
              УВІЙТИ
            </Button>
          </Form.Item>
          <Divider>або</Divider>
          <Form.Item>
            <Button type="primary" onClick={() => {
              dispatch(resetStore())
              navigate('/register')
            }} className="login-form-button">
              ЗАРЕЄСТРУВАТИСЬ
            </Button>
          </Form.Item>
        </Form>
      </motion.div>
    </Flex>
  );
};
export default SignIn;