import React from "react";
import { Table } from 'antd';
import 'dayjs/locale/uk';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { useGetUserDetailsQuery } from '../../services/auth/authService'

const BillSubComponent = () => {
    const { data: userInfo = {}, isFetching: isUserInfoFetching } = useGetUserDetailsQuery()
    dayjs.extend(LocalizedFormat);
    const columns = [
        {
            title: 'Назва',
            dataIndex: 'description',
            width: '20%',
            key: 'description',
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            width: '30%',
            key: 'date',
            render: (date) => dayjs(date).locale('uk').format('LLLL')
        },
        {
            title: 'Сума',
            dataIndex: 'price',
            key: 'price',
            width: '20%',
        },
        {
            title: 'Оплачено',
            dataIndex: 'paid',
            key: 'paid',
            width: '21%',
            render: (paid) => paid ? 'Так' : 'Ні'
        },
    ];
    return (
        <Table
            virtual
            bordered
            scroll={{
                x: 100,
                y: 300
            }}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={false}
            dataSource={userInfo.bills}
            rowClassName={(record) => !record.paid ? 'amount-negative' : 'amount-positive'}
            size="middle"
            loading={isUserInfoFetching}
        />
    )
}

export default BillSubComponent