import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Menu, theme, Flex, Row, Col, Typography } from 'antd';
import { HomeOutlined, ProfileOutlined, LogoutOutlined, LoginOutlined } from '@ant-design/icons';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import Home from '../../Pages/Home.js'
import Cabinet from '../../Pages/Cabinet.js'
import SignIn from '../../Pages/SignIn.js';
import Logout from '../../Pages/Logout.js';
import { ReactComponent as MasterCardLogo } from '../../img/MasterCard_Logo.svg'
import { ReactComponent as VisaLogo } from '../../img/visa.svg'
import ProtectedRoute from '../../routing/ProtectedRoute.js';
import Register from '../../Pages/Register.js';
import { AnimatePresence } from "framer-motion";
import Verify from '../../Pages/Verify.js';
import About from '../../Pages/About.js';
import Contract from '../../Pages/Contract.js';

const { Header, Content, Footer } = Layout;
const { Text, Title } = Typography;

const LayoutComponent = () => {
  let location = useLocation();
  const { token: { colorBgContainer, colorWhite } } = theme.useToken();
  const { userToken } = useSelector((state) => state.auth);
  const getMenuItems = () => {
    let items = [];
    items.push({
      label: <Link to="/">Головна</Link>,
      key: '/',
      icon: <HomeOutlined />
    });
    if (userToken !== null) {
      items.push({
        label: <Link to="/profile">Кабінет</Link>,
        key: '/profile',
        icon: <ProfileOutlined />
      });
      items.push({
        label: <Link to="/logout">Вихід</Link>,
        key: '/logout',
        icon: <LogoutOutlined />
      });
    } else {
      items.push({
        label: <Link to="/sign-in">Вхід</Link>,
        key: '/sign-in',
        icon: <LoginOutlined />
      });
    }
    return items;
  }
  return (
    <Layout>
      <Header style={{
        backgroundColor: colorBgContainer,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        alignItems: 'center',
      }}>
        <Row>
          <Col xxl={18} xl={16} lg={13} md={11} sm={5} xs={0}></Col>
          <Col xxl={6} xl={8} lg={11} md={13} sm={19} xs={24}>
            <Menu
              theme={'light'}
              mode="horizontal"
              defaultSelectedKeys={['/']}
              selectedKeys={location.pathname}
              items={getMenuItems()}
            />
          </Col>
        </Row>
      </Header>
      <Content className='Main-Content'>
        <AnimatePresence mode='wait' initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/profile" element={<Cabinet />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/verify" element={<Verify />} />
            </Route>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />
            <Route path='/about' element={<About/>}/>
            <Route path='/offer_agreement' element={<Contract/>} />
          </Routes>
        </AnimatePresence>
      </Content>
      <Footer className='footer' style={{ backgroundColor: '#141414' }}>
        <Row justify={'center'} align='center'>
          <Col span={12}>
            <Flex flex={1} gap='large' style={{ marginTop: 15 }} vertical justify='center' align='center'>
              <Text style={{ color: colorWhite }}>Платежі приймаються</Text>
              <MasterCardLogo width={100} height={100} />
              <VisaLogo width={100} height={100} />
            </Flex>
          </Col>
          <Col span={12}>
            <Flex gap='large' vertical>
              <Title level={3} style={{ color: colorWhite, marginTop: 0 }}>Зворотній зв'язок</Title>
              <Text style={{ color: colorWhite }}>Telegram: <a href='https://t.me/onegetman'>Перший Гетьман</a></Text>
              <Text style={{ color: colorWhite }}>Телефон: <a href='tel:+380500401898'>+380(50) 040 18 98</a></Text>
              <Text style={{ color: colorWhite }}>Обслуговування, ремонт і поліпшення прибудинкової території.<br /><Link to='/about'>ТОВ КЕРУЮЧА КОМПАНІЯ ГЕТЬМАН-1</Link></Text>
              <Text style={{ color: colorWhite }}><Link to='/offer_agreement'>Публічний договір</Link></Text>
              <Text style={{ color: colorWhite }}>Юридична адреса: 03057, м.Київ, вул.Вадима Гетьмана, буд.1</Text>
            </Flex>
          </Col>
        </Row>
      </Footer>
    </Layout>

  );
};
export default LayoutComponent;
