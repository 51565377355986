import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons';
import backgroundImage from '../img/bg_clear.jpg';
import { Button, Flex, Form, Input, Select, notification, Typography, Divider, theme, InputNumber } from 'antd';
import { resetStore, userRegistering } from '../features/auth/authActions';
import { motion } from "framer-motion";
const { Title } = Typography;
const { useToken } = theme;
const { Option } = Select;

const Register = () => {
  const [form] = Form.useForm();
  const [selectedAddress, setSelectedAddress] = useState('');
  const { loading, error, success } = useSelector((state) => state.auth)
  const { token } = useToken();
  const navigate = useNavigate();
  const openSuccessNotification = () => {
    notification.info({
      message: 'Повідомлення',
      duration: 0,
      description: 'Вам відправлено смс на номер вказаний при реєстрації. Будь ласка введіть код з смс. Якщо не ввести код з смс ваш акаунт буде видалено через 5 хв'
    });
  };
  const openErrorNotification = (mes) => {
    notification.error({
      message: 'Повідомлення',
      description: mes
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (success) {
      openSuccessNotification()
      navigate('/verify')
    }
    if (error) {
      openErrorNotification(error)
    }
  }, [navigate, error, success]);

  const onFormSave = (values) => {
    dispatch(userRegistering({ keys_count: 1, ...values }));
  }
  const flatNumbers = () => {
    const flats = [...Array(170).keys()].map((i) => i + 1);
    flats.splice(162, 0, "162-a")
    flats.splice(167 + 1, 0, "167-1", "167-2", "167-3", "167-4")
    flats.splice(170 + 5, 0, "170-1", "170-2", "170-3", "170-4")
    return flats.map((i) => <Option key={i} value={i}>{i}</Option>)
  }
  return (
    <Flex justify="center" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }} align="center">
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.3 }}
      >
        <Form
          style={{ backgroundColor: token.colorBgBase }}
          form={form}
          name="register"
          initialValues={{
          }}
          onFinish={onFormSave}
          layout="vertical"
          className='register-form'
          size='default'
        >
          <Title level={2} style={{ textAlign: 'center' }}><UserOutlined />Реєстрація</Title>
          <Form.Item
            label="Ім'я"
            name="first_name"
            rules={[
              {
                required: true,
                message: 'Будь ласка вкажіть ваше ім\'я',
              },
              {
                min: 3,
                message: 'Мінімальна довжина має бути 3 символи',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Прізвище"
            name="last_name"
            rules={[
              {
                required: true,
                message: 'Будь ласка вкажіть ваше прізвище',
              },
              {
                min: 3,
                message: 'Мінімальна довжина має бути 3 символи',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Номер телефону"
            name="phone"
            help={"Номер має мати вигляд: +380123456789"}
            rules={[
              {
                required: true,
                message: 'Будь ласка вкажіть номер телефону',
              },
              {
                pattern: '^\\+380[0-9]{9}$',
                message: 'Вкажіть правильний номер телефону'
              }
            ]}
          >
            <Input type="tel" />
          </Form.Item>
          <Form.Item
            label="Адреса"
            name="address"
            rules={[
              {
                required: true,
                message: 'Будь ласка вкажіть адресу',
              },
            ]}
          >
            <Select onChange={(val) => setSelectedAddress(val)}>
              <Option key={1} value="Вадима Гетьмана 1А">
                Вадима Гетьмана 1А
              </Option>
              <Option key={2} value="Вадима Гетьмана 1Б">Вадима Гетьмана 1Б</Option>
              <Option key={3} value="Вадима Гетьмана 1В">Вадима Гетьмана 1В</Option>
              <Option key={4} value="Вадима Гетьмана 1">Вадима Гетьмана 1</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Квартира"
            name="flat"
            rules={[
              {
                required: true,
                message: 'Будь ласка вкажіть квартиру',
              },
            ]}
          >
            <Select showSearch>
              {flatNumbers()}
            </Select>
          </Form.Item>
          {selectedAddress === "Вадима Гетьмана 1" &&
            <div>
              <Form.Item
                label="Кількість ключів"
                name="keys_count"
                rules={[
                  {
                    required: true,
                    message: 'Будь ласка вкажіть кількість ключів',
                  },
                ]}
              >
                <InputNumber min={1} controls={false} />
              </Form.Item>
              <Form.Item
                label="Назва фірми"
                name="firm_name"
                rules={[
                  {
                    required: true,
                    message: 'Будь ласка вкажіть назву фірми',
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          }
          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                min: 6,
                message: 'Мінімальна довжина паролю має бути 6 символів',
              },
              {
                required: true,
                message: 'Будь ласка вкажіть пароль'
              }
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} block type="primary" htmlType="submit">
              ЗАРЕЄСТРУВАТИСЬ
            </Button>
          </Form.Item>
          <Divider>або</Divider>
          <Form.Item>
            <Button type="primary" onClick={() => {
              dispatch(resetStore())
              navigate('/sign-in')
            }} className="login-form-button">
              УВІЙТИ
            </Button>
          </Form.Item>
        </Form>
      </motion.div>
    </Flex>
  );
};
export default Register;