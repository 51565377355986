import { createAsyncThunk } from "@reduxjs/toolkit";

export const userLogin = createAsyncThunk(
  'auth/login',
  async (values, { rejectWithValue }) => {

    try {
      const response = await fetch("/api/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Csrftoken": document.cookie.csrftoken
        },
        credentials: "omit",
        body: JSON.stringify(values)
      });
      if (!response.ok) {
        return rejectWithValue(response.status === 404 ? 'Неправильний номер чи пароль' : 'Не вдається увійти, будь ласка спробуйте пізніше')
      }
      const data = await response.json();
      localStorage.setItem('token', data.token);
      return data;
    } catch (e) {
      return rejectWithValue('Неуспішний вхід')
    }
  }
)

export const userRegistering = createAsyncThunk(
  'auth/register',
  async (values, { rejectWithValue }) => {

    try {
      const response = await fetch("/api/register/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Csrftoken": document.cookie.csrftoken
        },
        credentials: "omit",
        body: JSON.stringify(values)
      });
      if (!response.ok) {
        const data = response.status === 400 ? await response.json() : [];
        const err = Object.values(data).join('')
        return rejectWithValue(err ? err : 'Не вдається зареєструватись, будь ласка спробуйте пізніше')
      }
      const data = await response.json();
      localStorage.setItem('token', data.token);
      return data;
    } catch (e) {
      return rejectWithValue('Неуспішна реєстрація')
    }
  }
)

export const userLogout = createAsyncThunk(
  'auth/logout',
  async (_) => {
    localStorage.removeItem('token');
  }
)

export const resetStore = createAsyncThunk(
  'auth/reset',
  async (_) => { }
)