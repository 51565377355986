import React from "react";
import { Table } from 'antd';
import 'dayjs/locale/uk';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { useGetUserDetailsQuery } from '../../services/auth/authService'

const ReplenishmentSubComponent = () => {
    const { data: userInfo = {}, isFetching: isUserInfoFetching } = useGetUserDetailsQuery()
    dayjs.extend(LocalizedFormat);
    const columns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            width: '70%',
            render: (date) => dayjs(date).locale('uk').format('LLLL')
        },
        {
            title: 'Сума',
            dataIndex: 'amount',
            key: 'amount',
            width: '30%',
            render: (amount) => parseInt(amount) >= 0 ? `+${amount}` : amount
        },
    ];
    return (
        <Table
            virtual
            bordered
            scroll={{
                x: 100,
                y: 300
            }}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={false}
            dataSource={userInfo.transactions?.filter(i => i.amount > 0)}
            rowClassName={(record) => parseInt(record.amount) < 0 ? 'amount-negative' : 'amount-positive'}
            size="middle"
            loading={isUserInfoFetching}
        />
    )
}

export default ReplenishmentSubComponent