import React from "react";
import { Typography } from 'antd';
import { Link } from 'react-router-dom'
import 'dayjs/locale/uk';
const { Text, Title, Paragraph } = Typography;

const AboutSubComponent = () => {
    return (
        <div>
            <Title level={5} style={{marginTop: 0}}>Обслуговування, ремонт і поліпшення прибудинкової території.</Title>
            <Link to='/about'>Керуюча компанія "Гетьман-1"</Link>
            <Title level={5}>Інформація про способи доставки, оплати товару та умови повернення:</Title>
            <Paragraph><Text strong>Вартість: </Text><Text>один акаунт 50 гривень. Один аккаунт отримує доступ до додатка PalGate на 1 пристрої і може мати брелок і пульт від хвірток і воріт.</Text></Paragraph>
            <Paragraph><Text strong>Публічний договір можно переглянути тут: </Text> <Link to='/contract'>договір</Link></Paragraph>
        </div>
    )
}

export default AboutSubComponent