import React from 'react';
import { Typography } from 'antd';
import { motion } from "framer-motion";
const { Title, Paragraph, Text } = Typography;

const Contract = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.3 }}
    >
      <div className='contract'>
        <Title level={2}>ПУБЛІЧНА ОФЕРТА ПРО УКЛАДЕННЯ ДОГОВОРУ ПРО НАДАННЯ ПОСЛУГ</Title>
        <Paragraph>
          <Text>Товариство з обмеженою відповідальністю “Керуюча компанія “Гетьман-1” (далі – “Виконавець”), в особі виконавчого директора Гацка Сергія Олександровича, що діє на підставі Статуту організації (протокол №1 від 04.12.2020) та відповідно до законодавства України, керуючись ст.ст. 633, 641 Цивільного кодексу України, пропонує необмеженому колу фізичних осіб, укласти цей Договір про надання послуг (далі – Договір) на наступних умовах:</Text>
          <ol>
            <Title level={4}><li>Терміни та загальні положення.
              <ol>
                <Title level={5}><li>Послуга — яку надає Виконавець у порядку та на умовах, визначених цим Договором.</li></Title>
                <Title level={5}><li>Публічна оферта — пропозиція Виконавця (викладена на Сайті Виконавця), адресована необмеженому колу фізичних осіб відповідно до законодавства України, укласти цей Договір на певних умовах.</li></Title>
                <Title level={5}><li>Сайт Виконавця — веб-сторінка в мережі Інтернет за адресою <a href='https://getman.biz'>https://getman.biz</a>, яка є офіційним джерелом інформування Користувачів про Виконавця та послуги.</li></Title>
                <Title level={5}><li>Акцепт — повне, безумовне та беззаперечне прийняття Користувачем умов Публічної оферти цього Договору.</li></Title>
                <Title level={5}><li>Користувач — фізична особа, яка здійснила Акцепт Публічної оферти Виконавця, викладеної в цьому Договорі, та сплатила вартість Послуг.</li></Title>
                <Title level={5}><li>Сторони — Виконавець та Користувач.</li></Title>
              </ol></li></Title>
            <Title level={4}><li>Предмет договору.
              <ol>
                <Title level={5}><li>Виконавець бере на себе зобов'язання надати Користувачеві послуги з обслуговування на території двору відповідно до умов цього Договору, а Користувач зобов'язується здійснити оплату таких Послуг.</li></Title>
              </ol></li></Title>
            <Title level={4}><li>Умови надання Послуги.
              <ol>
                <Title level={5}><li>Послуга неподільна і складається з організації та обслуговування системи контролю доступу (СКД),
прибирання території двору, ремонту та обслуговуванню інфраструктури двору, дитячого майданчика тощо.</li></Title>
                <Title level={5}><li>Оплата за послугу здійснюється щомісяця в розмірі 50 (п'ятдесят) гривень.</li></Title>
                <Title level={5}><li>Акцептом цього Договору визнається оплата Клієнтом, повна або часткова, і означає повне та беззастережне прийняття Користувачем умов цього Договору та прирівнюється до власноручного підпису Користувача під Договором. З моменту першого зарахування грошових коштів на рахунок Виконавця, ця оферта вважається акцептованою, а договір укладеним.</li></Title>
                <Title level={5}><li>Щомісяця 1 числа програма автоматично виставляє рахунок на оплату всім Користувачам.</li></Title>
                <Title level={5}><li>10 числа кожного місяця Користувач, який має від'ємний баланс з урахуванням виставленого 1 числа рахунку, відключається від СКД і може проходити на територію двору тільки через хвіртку в бік вулиці Сім'ї Бродських.</li></Title>
                <Title level={5}><li>Відключення від СКД не звільняє Користувача від виконання зобов'язань за цим договором.</li></Title>
                <Title level={5}><li>Після оплати боргу Користувач підключається до СКД у такому порядку:
                  <ol>
                    <Title level={5}><li>Якщо оплата здійснювалася через додаток або на сайті <a href='https://getman.biz'>https://getman.biz</a>, то підключення здійснюється в автоматичному режимі через деякий час.</li></Title>
                    <Title level={5}><li>Якщо оплата здійснювалася іншим способом, то необхідно надіслати квитанцію про оплату у Viber на тел. <a href='tel:+380500401898'>+38 (050)-040-18-98</a></li></Title>
                  </ol></li></Title>
              </ol></li></Title>
            <Title level={4}><li>Права та обов'язки Виконавця.
              <ol>
                <Title level={5}><li>Виконавець має право:
                  <ol>
                    <Title level={5}><li>Отримати за надані послуги оплату.</li></Title>
                    <Title level={5}><li>Відключати в СКД доступ Користувача до воріт за порушення правила знаходження автомобіля на території подвір'я більше 30 хвилин.</li></Title>
                  </ol></li></Title>
                <Title level={5}><li>Виконавець зобов'язується:
                  <ol>
                    <Title level={5}><li>Надавати Користувачеві комунальні послуги в обсязі відповідно до цього Договору.</li></Title>
                    <Title level={5}><li>Інформувати Користувача про правила та вимоги щодо організації надання послуг, їх якості та змісту, про права та обов'язки Користувача при отриманні послуг.</li></Title>
                    <Title level={5}><li>Підтримувати та покращувати інфраструктуру подвір'я, утримувати її в чистоті та справному вигляді.</li></Title>
                  </ol></li></Title>
              </ol></li></Title>
            <Title level={4}><li>Права та обов'язки Користувача.
              <ol>
                <Title level={5}><li>Користувач має право:
                  <ol>
                    <Title level={5}><li>Отримати Послуги належної якості відповідно до Договору.</li></Title>
                    <Title level={5}><li>Отримувати щорічний звіт про діяльність пов'язану з обслуговуванням двору.</li></Title>
                    <Title level={5}><li>Користуватися інфраструктурою двору.</li></Title>
                  </ol></li></Title>
                <Title level={5}><li>Користувач зобов'язується:
                  <ol>
                    <Title level={5}><li>Своєчасно вносити плату за Послуги.</li></Title>
                    <Title level={5}><li>Дотримуватися правила в'їзду на територію двору автомобілів - не більше 30 хвилин.</li></Title>
                    <Title level={5}><li>Дотримуватися на території двору чистоти та порядку.</li></Title>
                    <Title level={5}><li>Виконувати вимоги законодавства України та вимоги Договору.</li></Title>
                    <Title level={5}><li>Утриматися в процесі користування інфраструктурою двору від дій, які:
                      <ol>
                        <Title level={5}><li>можуть перешкодити іншим Користувачам, запрошеним особам користуватися інфраструктурою двору.</li></Title>
                        <Title level={5}><li>можуть призвести до пошкодження майна інших Користувачів, запрошених осіб, спільного майна або майна Виконавця.</li></Title>
                        <Title level={5}><li>порушують громадський порядок, зокрема ображають присутніх, виражаються у використанні нецензурних виразів та/або створенні шуму.</li></Title>
                      </ol></li></Title>
                  </ol></li></Title>
              </ol></li></Title>
            <Title level={4}><li>Вартість послуг і порядок оплати.
              <ol>
                <Title level={5}><li>Загальна вартість послуг, надана Користувачеві за цим Договором, становить 50 (п'ятдесят) гривень на місяць за 1 акаунт.</li></Title>
                <Title level={5}><li>Оплата за поточний місяць здійснюється не пізніше 9 числа поточного місяця.</li></Title>
                <Title level={5}><li>Користувач може здійснити авансовий платіж за кілька місяців наперед.</li></Title>
                <Title level={5}><li>Користувач може отримати повернення невикористаної частини авансового платежу. При цьому оплата за поточний і минулі місяці не повертається.</li></Title>
              </ol></li></Title>
            <Title level={4}><li>Відповідальність Сторін.
              <ol>
                <Title level={5}><li>За невиконання або неналежне виконання зобов'язань за цим договором сторони несуть відповідальність відповідно до чинного законодавства України.</li></Title>
                <Title level={5}><li>За порушення пункту 5.2.2. вперше - блокування в'їзду у двір на 14 днів, вдруге - блокування в'їзду у двір на 30 днів, систематично - блокування в'їзду у двір на весь час.</li></Title>
              </ol></li></Title>
            <Title level={4}><li>Форс-мажор.
              <ol>
                <Title level={5}><li>Сторони звільняються від відповідальності за невиконання зобов'язань, якщо це невиконання спричинене обставинами, що не залежать від волі Сторін, а саме: воєнними діями, стихійним лихом, техногенними та іншими аваріями, страйками, локаутами, актами органів влади чи управління тощо, що унеможливлює виконання умов цього Договору (далі Форс-мажор).</li></Title>
                <Title level={5}><li>Форс-мажор застосовується, і Сторона, для якої він настав, звільняється від відповідальності за порушення умов цього Договору, за наявності письмового підтвердження (висновок, довідка) Торгово-промислової палати України про настання форс-мажору.</li></Title>
                <Title level={5}><li>Сторона, для якої настав Форс-мажор, зобов'язується негайно повідомити іншу Сторону і надати документи, що підтверджують Форс-мажор.</li></Title>
                <Title level={5}><li>З моменту отримання такого повідомлення іншою Стороною виконання умов цього Договору призупиняються на весь період дії Форс-мажору.</li></Title>
                <Title level={5}><li>У разі дії Форс-мажорних обставин понад 3 місяці кожна зі сторін має право ініціювати припинення Договору.</li></Title>
              </ol></li></Title>
            <Title level={4}><li>Припинення Договору.
              <ol>
                <Title level={5}><li>Дія цього Договору припиняється:
                  <ol>
                    <Title level={5}><li>За згодою сторін;</li></Title>
                    <Title level={5}><li>Якщо виконання Стороною Договору своїх зобов'язань є неможливим у зв'язку з прийняттям нормативно-правових актів, що змінили умови, встановлені даним Договором, та будь-яка зі Сторін не погоджується про внесення змін до Договору.</li></Title>
                  </ol></li></Title>
              </ol></li></Title>
            <Title level={4}><li>Обробка персональних даних.
              <ol>
                <Title level={5}><li>Користувач підтверджує, що добровільно та безоплатно надає згоду на обробку своїх персональних даних (включно з прізвищем, ім'ям та по-батькові, зареєстрованим місцем проживання та/або фактичним місцем проживання, ідентифікаційним номером, даними про державну реєстрацію; банківськими реквізитами, номерами телефонів та адресами електронної пошти, тощо) в базі персональних даних Виконавця, зокрема на збирання, реєстрацію, включення до бази даних, накопичення, зберігання, накопичення, зберігання, адаптування, зміну, поновлення, використання, поширення (розкриття та передачу), передачу (передачу, передачу, передачу, передачу, передачу, передачу та передачу) своїх персональних даних третім особам в мінімально необхідних обсягах та лише з метою виконання зобов'язань за цим Договором. Користувач надає згоду на передачу своїх персональних даних третім особам у мінімально необхідних обсягах і лише з метою виконання зобов'язань за цим Договором, які відповідають об'єктивній причині збору відповідних даних.</li></Title>
                <Title level={5}><li>Користувач підтверджує, що йому було повідомлено про його права, визначені Законом України «Про захист персональних даних» від 1 червня 2010 р. № 2297-VI, зі змінами та доповненнями, про мету обробки та збору персональних даних.</li></Title>
              </ol></li></Title>
            <Title level={4}><li>Термін дії Оферти.
              <ol>
                <Title level={5}><li>Ця Публічна оферта набирає чинності з моменту її розміщення на Сайті Виконавця і діє до моменту її відкликання Виконавцем.</li></Title>
                <Title level={5}><li>Виконавець має право в будь-який час внести зміни до умов Оферти та/або відкликати Оферту в будь-який момент на свій розсуд. У разі внесення Виконавцем змін до Оферти, такі зміни набирають чинності з моменту розміщення зміненого тексту Оферти на Сайті Виконавця, якщо інший строк набрання змінами чинності не визначено безпосередньо в тексті зміненої Оферти.</li></Title>
              </ol></li></Title>
            <Title level={4}><li>Прикінцеві положення.
              <ol>
                <Title level={5}><li>Сторони встановили, що будь-які суперечки та претензії будуть вирішуватися сторонами шляхом переговорів.</li></Title>
                <Title level={5}><li>Сторони усвідомлюють, що Послуги надаються Виконавцем, який зареєстрований і діє за законодавством України, місце проживання якого також зареєстровано в Україні.</li></Title>
                <Title level={5}><li>Місцем вчинення цього правочину та місцем надання Послуг Сторони є територія подвір'я житлового комплексу за адресою: м. Київ, вулиця Вадима Гетьмана 1.</li></Title>
                <Title level={5}><li>Акцептуючи дану Оферту Користувач погоджується, що всі суперечки, пов'язані з даним Договором, будуть розглядатися відповідно до законодавства України. Користувач також погоджується, що всі такі спори перебувають у виключній компетенції судів України.</li></Title>
                <Title level={5}><li>Заголовки, що використовуються в статтях і пунктах цієї Оферти, використовуються тільки для посилань і зручності користування текстом. Ці заголовки не можуть розглядатися як такі, що визначають, обмежують або змінюють, або впливають на значення і зміст умов цієї Оферти або її будь-якої частини.</li></Title>
                <Title level={5}><li>Якщо якесь із положень цієї Оферти буде визнано недійсним, то дійсність її інших положень від цього не втрачається.</li></Title>
                <Title level={5}><li>У всіх випадках, не передбачених цією Офертою, Сторони керуються чинним законодавством України.</li></Title>
              </ol></li></Title>
            <Title level={4}><li>Реквізити Виконавця.
              <ol>
                <Title level={5}><li>Виконавець: <Text copyable>ТОВ КЕРУЮЧА КОМПАНІЯ ГЕТЬМАН-1</Text></li></Title>
                <Title level={5}><li>Код за ЄДРПОУ: <Text copyable>44017359</Text></li></Title>
                <Title level={5}><li>IBAN: <Text copyable>UA753052990000026001030110821</Text></li></Title>
                <Title level={5}><li>Банк: <Text copyable>АТ КБ "ПРИВАТБАНК"</Text></li></Title>
                <Title level={5}><li>Контактний телефон: <a href='tel:+380500401898'>+38 (050)-040-18-98</a></li></Title>
              </ol></li></Title>
          </ol>
        </Paragraph>
      </div>
    </motion.div>
  );
};
export default Contract;